import React from 'react';
import { graphql } from 'gatsby';
import { useEffect } from 'react';
import SEO from '@components/seo';
import Layout from '@components/Layout/Layout';
import { navigate } from 'gatsby';

import BlogLayout from '@components/Blog/BlogLayout';
import SocialShareSection from '@components/Blog/SocialShareSection';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlogDateAndReadTime } from '@components/Blog/BlogCard';
import { DiscussionEmbed } from 'disqus-react';

import { siteUrl, disqusShortName } from '../../config/website';

const BlogPost = ({ data, pageContext }) => {
  const { title, date } = data.markdownRemark.frontmatter;
  const { timeToRead, html, excerpt, id } = data.markdownRemark;

  const baseSlugUrl = siteUrl + pageContext.slug;
  const disqusConfig = {
    identifier: id,
    title: title,
    url: baseSlugUrl,
  };

  const Link = `https://www.seelay.in${pageContext.slug}`;

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function(e) {
        // do something here...
        e.preventDefault();
      },
      false
    );
  }, []);

  return (
    <Layout>
      <SEO
        isBlogPost
        title={title}
        description={excerpt}
        slug={pageContext.slug}
      />

      <BlogLayout
        sharerSection={
          <div>
            <h4>Share on</h4>
            <SocialShareSection baseSlugUrl={baseSlugUrl} title={title} />

            <hr style={{ margin: '25px 0' }} />
            <a
              style={{ fontSize: '16px' }}
              aria-label="Copy link of this post to clipboard"
              rel="noopener norefferer"
              target="__blank"
              href={Link}
              onClick={e => {
                e.preventDefault(); // Prevent default link behavior
                navigator.clipboard
                  .writeText(Link)
                  .then(() => {
                    alert('Link copied to clipboard!'); // Show a success message
                  })
                  .catch(() => {
                    alert('Failed to copy the link. Please try again.'); // Show an error message if it fails
                  });
              }}
            >
              <FontAwesomeIcon style={{ fontSize: 18 }} icon="copy" /> Link of
              this post
            </a>
          </div>
        }
      >
        <BlogDateAndReadTime date={date} readtime={timeToRead} />

        <a
          style={{ fontSize: '16px', marginLeft: '16px', cursor: 'pointer' }}
          aria-label="Go Back"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon style={{ fontSize: 16 }} icon="chevron-left" /> Go
          Back
        </a>
        <h1>{title}</h1>
        <article
          className="markdown-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <DiscussionEmbed shortname={disqusShortName} config={disqusConfig} />
      </BlogLayout>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt
      html
      timeToRead
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY", locale: "en")
        title
      }
    }
  }
`;

export default BlogPost;
